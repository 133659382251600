<template>
  <div>
    <h3 class="pb-2">
      Tenant Settings for {{ tenant.name }}
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.tenantSettingsForm"
    />
    <div v-if="!loading">
      <ValidationObserver ref="tenantSettingsForm">
        <form @submit.prevent="onSubmit">
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">Website - Header Information</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Department Name"
                    id="name"
                    disabled
                    v-model="tenant.departmentName"
                  />
                  <small class="form-text text-muted mb-2"
                    >The Department Name shown in the website header.</small
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="tenantSiteName"
                    name="Site Name"
                    id="icon"
                    v-model="tenant.siteName"
                  />
                  <small class="form-text text-muted mb-2"
                    >The name for the website shown in the website
                    header.</small
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <TextInput
                    name="Tenant Tagline"
                    id="name"
                    rules="alpha_num_special"
                    v-model="tenant.tagline"
                  />
                  <small class="form-text text-muted mb-2"
                    >The tagline for tenant shown in the website header.</small
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">Website - Footer Information</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Facebook"
                    id="Facebook"
                    v-model="tenant.facebookUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the tenant’s Facebook in the website
                    footer.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Instagram"
                    id="Instagram"
                    v-model="tenant.instagramUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the tenant’s Instagram in the
                    website footer.
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Twitter"
                    id="Twitter"
                    v-model="tenant.twitterUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the tenant’s Twitter in the website
                    footer.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Pinterest"
                    id="Pinterest"
                    v-model="tenant.pinterestUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the tenant’s Pinterest in the
                    website footer.
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Reservation Policy"
                    id="reservationPolicy"
                    v-model="reservationPolicy"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the tenant’s reservation policy in
                    the website footer.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Cancellation Policy"
                    id="cancellationPolicy"
                    v-model="cancellationPolicy"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the tenant’s cancellation policy in
                    the website footer.
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Reservation Information"
                    id="reservationInformation"
                    v-model="reservationInformation"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the tenant’s reservation policy in
                    the website footer.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Contact Us"
                    id="contactUs"
                    rules="email"
                    v-model="supportContactUs"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Admin Online Help URL"
                    id="adminOnlineHelpUrl"
                    v-model="tenant.adminOnlineHelpUrl"
                  />
                  <small class="form-text text-muted mb-2"> </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Customer Online Help URL"
                    id="customerOnlineHelpUrl"
                    v-model="tenant.customerOnlineHelpUrl"
                  />
                  <small class="form-text text-muted mb-2"> </small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Contact Support"
                    id="phone"
                    v-model="supportPhone"
                    rules="phone"
                  />
                </div>
                <div class="col-md-6 col-sm-12"></div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-title mb-0">Website - Other Information</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Banner Image"
                    id="bannerImage"
                    rules="image"
                    v-model="tenant.bannerImageUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the banner image on the customer
                    homepage.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Default Park Image"
                    id="defaultParkImage"
                    rules="image"
                    v-model="tenant.defaultLocationImageUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the default image shown for those
                    parks that do not have images configured specifically.
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Default Product Type Icon"
                    id="defaultProductTypeIconImage"
                    rules="image"
                    v-model="tenant.defaultProductImageUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The icon shown for those product types that do not have
                    icons configured specifically.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="State Logo"
                    id="stateLogo"
                    rules="image"
                    v-model="tenant.stateLogoUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the State Logo for the tenant. Not
                    currently used.
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Department Logo"
                    id="departmentLogo"
                    rules="image"
                    v-model="tenant.departmentLogoUrl"
                  />
                  <small class="form-text text-muted mb-2">
                    The URL linking back to the Department Logo for the tenant.
                    Not currently used.
                  </small>
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Site Title"
                    id="siteTitle"
                    v-model="tenant.siteTitle"
                  />
                  <small class="form-text text-muted mb-2">
                    The Site Title for the tenant that shows in the browswer
                    tab.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
            Save
          </button>
          <button
            class="btn btn-cancel mt-4 mr-2"
            @click="cancel"
            type="button"
          >
            Cancel
          </button>
          <p class="mt-2">
            <span style="color: red">*</span> Indicates a required field
          </p>
        </form>
      </ValidationObserver>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import AdminTenantService from "@/services/admin/AdminTenantService.js";
import { ValidationObserver } from "vee-validate";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "AdminTenantSettings",
  title: "Admin - Tenant Settings",
  components: {
    ValidationObserver,
    FormErrorAlert,
    TextInput
  },
  data() {
    return {
      loading: false,
      tenant: {},
      errors: []
    };
  },
  methods: {
    cancel() {
      this.$router.push("/admin");
    },
    async getTenant() {
      this.loading = true;
      const service = new AdminTenantService(this.tenantId);
      const response = await service.getTenant();
      if (response?.statusCode === "Success") {
        this.tenant = response.tenant;
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    async onSubmit() {
      this.$refs.tenantSettingsForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.tenantSettingsForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.tenantSettingsForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            const service = new AdminTenantService(this.tenantId);

            const response = await service.updateTenant(this.tenant);
            if (response?.statusCode === "Success") {
              window.scroll(0, 0);
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: "Tenant Settings successfully modified.",
                layer: "admin"
              });
              this.getTenant();
            } else {
              this.loading = false;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservationPolicy: {
      get() {
        return this.tenant.policies.find(x => x.name === "Reservation Policy")
          ?.url;
      },
      set(val) {
        const policy = this.tenant.policies.find(
          x => x.name === "Reservation Policy"
        );
        if (!policy) {
          this.tenant.policies.push({
            name: "Reservation Policy",
            tenantId: this.tenantId,
            url: val
          });
        } else {
          this.tenant.policies[this.tenant.policies.indexOf(policy)].url = val;
        }
      }
    },
    cancellationPolicy: {
      get() {
        return this.tenant.policies.find(x => x.name === "Cancellation Policy")
          ?.url;
      },
      set(val) {
        const policy = this.tenant.policies.find(
          x => x.name === "Cancellation Policy"
        );
        if (!policy) {
          this.tenant.policies.push({
            name: "Cancellation Policy",
            tenantId: this.tenantId,
            url: val
          });
        } else {
          this.tenant.policies[this.tenant.policies.indexOf(policy)].url = val;
        }
      }
    },
    reservationInformation: {
      get() {
        return this.tenant.externalLinks.find(
          x => x.name === "Reservation Information"
        )?.url;
      },
      set(val) {
        const link = this.tenant.externalLinks.find(
          x => x.name === "Reservation Information"
        );
        if (!link) {
          this.tenant.externalLinks.push({
            name: "Reservation Information",
            tenantId: this.tenantId,
            url: val
          });
        } else {
          this.tenant.externalLinks[
            this.tenant.externalLinks.indexOf(link)
          ].url = val;
        }
      }
    },
    supportUrl: {
      get() {
        return this.tenant.supportInfo?.url;
      },
      set(val) {
        if (!this.tenant.supportInfo) {
          this.tenant.supportInfo = {
            tenantId: this.tenantId,
            url: val
          };
        } else {
          this.tenant.supportInfo.url = val;
        }
      }
    },
    supportPhone: {
      get() {
        return this.tenant.supportInfo?.phoneNumber;
      },
      set(val) {
        if (!this.tenant.supportInfo) {
          this.tenant.supportInfo = {
            tenantId: this.tenantId,
            phoneNumber: val
          };
        } else {
          this.tenant.supportInfo.phoneNumber = val;
        }
      }
    },
    supportContactUs: {
      get() {
        return this.tenant.supportInfo?.email;
      },
      set(val) {
        if (!this.tenant.supportInfo) {
          this.tenant.supportInfo = {
            tenantId: this.tenantId,
            email: val
          };
        } else {
          this.tenant.supportInfo.email = val;
        }
      }
    }
  },
  created() {
    this.getTenant();
  }
};
</script>

<style></style>
